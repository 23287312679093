import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const ArticleListBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="container"
      blockDirection="vertical"
      showHeadline="true"
      className=""
    >
      <>
        {block.article_items?.length > 0 &&
          block.article_items.map((item, index) => {
            const content = JSON.parse(item.content)
            return (
              <Link className="group" link={`/${item.full_slug}`} key={index}>
                <div className="flex flex-row flex-wrap py-4 border-b-2 border-gray-200 md:py-8">
                  <div className="md:pr-4 md:w-1/2 lg:w-1/3">
                    {content.image?.filename?.length > 0 && (
                      <div className="relative block w-full h-full">
                        <div className="inset-0 overflow-hidden md:absolute ">
                          <Image
                            className="object-cover object-center md:w-full md:h-full"
                            image={content.image}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="md:pl-4 md:w-1/2 lg:w-2/3">
                    <div className="mt-4 md:mt-0">
                      <div className="flex flex-row flex-wrap justify-between">
                        <span className="block text-gray-700">
                          {content.kicker}
                        </span>
                        {(item.isMember || item.isAd) && (
                          <span className="px-2 text-white bg-primary">
                            {item.isAd && "Anzeige"}
                            {item.isMember && "Nur für Mitglieder"}
                          </span>
                        )}
                      </div>
                      <span className="block mt-2 headline headline-h4">
                        {content.title}
                      </span>
                      <div className="mt-4 prose max-w-none">
                        <p className="inline mr-2">{content.teaser}</p>
                        <span className="text-red-500 group-hover:text-primary">
                          Weiterlesen
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
      </>
    </BlockWrapper>
  )
}

export default ArticleListBlock
